import React from "react";
import AppleIcon from "../Assets/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg";
import GoogleIcon from "../Assets/google-play-badge.png";

const About = () => {
  return (
    <div className="about-section-container">
        <h1 className="primary-heading">
          遊び方
        </h1>
      <div className="primary-text_container">
        <p className="primary-text">・おなじみのナンプレのルールを使って指定された赤マスに入る数字を求めよう！</p>
        <p className="primary-text">・行や列に注目して入る数字を考えよう！</p>
        <p className="primary-text">・候補のメモ書きができるよ！</p>
        <p className="primary-text">・全マスにも挑戦できるよ！</p>
      </div>
      <div className="secondary-button">
        <a href="https://apps.apple.com/jp/app/a-number/id6450869818" target="blank_">
          <img src={AppleIcon} alt="" />
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.sudoku.anumber" target="blank_">
          <img src={GoogleIcon} alt="" />
        </a>
        
      </div>
      <div className="primary-image_container">

      </div>
    </div>
  );
};

export default About;
