import React from "react";
import BannerImage from "../Assets/top_image.png";
import AppleIcon from "../Assets/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg";
import GoogleIcon from "../Assets/google-play-badge.png";

import AboutBackground from "../Assets/re8.png";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-text-section">
          <h1 className="primary-heading">
            A Number
          </h1>
          <h2>絶対ハマる１マスナンプレ！！</h2>
          <p className="primary-text">
            「A Number」は、全てのマスを埋める必要がない、まったく新しい形式のナンプレクイズです。
            たった1マスだけを埋めることで、ナンプレ本来のおもしろさや奥深さに気付くことができます！
            初心者から上級者まで、どのようなプレーヤーでも楽しみながらナンプレ力を向上できます！
            より一層ナンプレが好きになること間違いなし！
          </p>
          <div className="secondary-button">
            <a href="https://apps.apple.com/jp/app/a-number/id6450869818" target="blank_">
              <img src={AppleIcon} alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.sudoku.anumber" target="blank_">
              <img src={GoogleIcon} alt="" />
            </a>
          </div>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
    </div>
  
  );
};

export default Home;
